<template>
  <div class="outgoing-payments">
    <!-- <vendor-payments-stats /> -->
    <div class="row pt-2">
      <div class="col-lg-6 py-3">
        <h3 class="heading">Incoming Company Payments</h3>
        <button
          @click="refresh"
          class="create-button"
          :class="fetchingRecords ? 'btn-disabled processing' : ''"
          :disabled="fetchingRecords"
        >
          Refresh
          <img
            class="ajax-loader"
            src="/media/ajax/white.gif"
            alt="Ajax loader"
          />
        </button>
      </div>
      <div class="col-lg-3"></div>
      <div class="col-lg-12 table-container">
        <template>
          <b-table
            hover
            :items="records"
            :fields="tableFields"
            :busy="fetchingRecords"
            class="table-light"
            @sort-changed="sortChanged"
            thead-class="outgoing-payments-head shadow-sm"
            tbody-class="outgoing-payments-body"
            @row-clicked="orderRowClicked"
          >
            <template #table-busy>
              <div class="text-center text-danger my-2">
                <b-spinner class="align-middle mx-4"></b-spinner>
                <strong>Fetching...</strong>
              </div>
            </template>
            <template v-slot:cell(order_no)="data">
              {{ data.value }}

              <!-- <div v-else-if="typeof(data.value) === 'string'"> -->
            </template>

            <template v-slot:cell(riders)="data">
              {{ data.value }}
            </template>
            <template v-slot:cell(status)="data">
              <!-- <span class="status"> -->
              <span
                v-if="data.value === 'requested'"
                class="status status__requested"
              >
                {{ data.value }}
              </span>

              <span
                v-else-if="data.value === 'pending'"
                class="status status__pending"
              >
                {{ data.value }}
              </span>

              <span
                v-else-if="data.value === 'verified'"
                class="status status__verified"
              >
                {{ data.value }}
              </span>
              <!-- </span> -->
            </template>
            <template v-slot:cell(verify)="data">
              <!-- <span class="status"> -->
              <button
                @click="companyPaymentVerification(data.value)"
                :items="records"
                class="create-button"
                :class="fetchingRecords ? 'btn-disabled processing' : ''"
                :disabled="fetchingRecords"
              >
                Verify
                <img
                  class="ajax-loader"
                  src="/media/ajax/white.gif"
                  alt="Ajax loader"
                />
              </button>
              <!-- </span> -->
            </template>
          </b-table>
        </template>
      </div>
      <template v-if="rows > 0">
        <div class="col-lg-12 d-flex justify-content-center">
          <b-pagination
            v-model="currentPage"
            :total-rows="rows"
            :per-page="perPage"
            aria-controls="active-orders-table"
            :current-page="currentPage"
            align="fill"
            first-number
            last-number
            @change="changePage"
          ></b-pagination>
        </div>
      </template>
    </div>

    <!-- :items="tableItems" -->
    <template v-if="modalData !== null">
      <OutgoingPayOrders
        ref="orderOutgoingModal"
        :orderDetails="modalData"
        @close="outgoingPaymentModalClosed"
      />
    </template>
  </div>
</template>

<script>
import { mapState } from "vuex";

import { GetCompanyPayments } from "@/core/services/store/payments.module";
import ApiService from "@/core/services/api.service";
export default {
  name: "VendorPaymentsPK",
  components: {},
  data() {
    return {
      modalData: null,
      responseData: "",
      currentPage: 1,
      perPageOptions: [
        { value: 10, text: "10" },
        { value: 25, text: "25" },
        { value: 50, text: "50" }
      ],
      sortBy: "-date",
      tableFields: [
        {
          key: "name",
          label: "Store Name",
          sortable: false
        },
        {
          key: "orders",
          label: "No. of Orders",
          sortable: false
        },
        {
          key: "date",
          label: "Date",
          sortable: true
        },
        {
          key: "riders",
          label: "Riders",
          sortable: false
        },
        {
          key: "amount",
          label: "Payment Amount",
          sortable: true
        },
        {
          key: "status",
          label: "Payment Status",
          sortable: true
        },
        {
          key: "verify",
          label: "Verification",
          sortable: true
        }
      ],
      perPage: 10
    };
  },
  methods: {
    refresh() {
      this.$store.dispatch(GetCompanyPayments, {
        riderId: "6333d890ba588f1464681aff",
        limit: [0, 10]
      });
    },
    async companyPaymentVerification(items) {
      await Promise.resolve(
        new Promise(resolve => {
          ApiService.post("rider/companyPaymentVerify", {
            items
          }).then(res => {
            console.log(res);
            if (res.data.success) {
              setTimeout(() => {
                resolve(true);
              }, 100);
            } else {
              resolve(false);
            }
          });
        })
      );
    }
  },
  computed: {
    ...mapState({
      fetchingRecords: ({ payments }) => payments.companyPaymentsLoading,
      rows: ({ payments }) => payments.length,
      companyPayments: ({ payments }) => payments.companyPayments,
      records: ({ payments }) => {
        const { companyPayments: recs } = payments;

        return recs.length < 1
          ? []
          : recs.map((item, index) => ({
              index,
              verify: item.paymentId,
              name: item.storeName,
              orders: item.totalOrders,
              date: item.createdAt,
              amount: item.totalAmount,
              status: item.status,
              riders: item.riderName
            }));
      }
    })
  },
  mounted() {
    this.$store.dispatch(GetCompanyPayments, {
      riderId: "6333d890ba588f1464681aff",
      limit: [0, 10]
    });
    setTimeout(() => {
      this.x = 10;
    }, 5000);
  }
};
</script>

<style lang="scss" scoped>
.outgoing-payments {
  // h3.heading {
  //   text-transform: uppercase;
  // }
  ::v-deep .outgoing-payments-head {
    tr {
      th {
        color: #763fb8;
        vertical-align: middle;
        font-size: 12px;
      }
    }
  }
  .status {
    color: #fff;
    background: #916266;
    padding: 5px 15px;
    margin-left: 10px;
    border-radius: 25px;
    display: flex;
    align-items: center;
    justify-content: center;
    &__pending {
      background: #dc1828;
    }
    &__requested {
      background: #283848;
    }
    &__verified {
      background: #4ff86f;
    }
  }
}
.select-PS {
  min-width: 50px;
  max-width: 120px;
  background: #283848;
  color: #e7e8ea;
  background-image: url("/media/payment/select-arrow-icon.svg");
  background-repeat: no-repeat;
  background-position: right;
  background-size: 18px;
}
</style>
